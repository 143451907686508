import $ from 'jquery';

export default class MeasureContentHandler {
    constructor(selector = '.measure-content', url = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js', config = 'TeX-AMS_HTML') {
        const $measureContent = $(selector);

        if ( !$measureContent.length ) {
            return;
        }

        let content = $measureContent.html();
        content = content.replace(/(((?:^|\n)\s*)[*•](.*))+/g, '<ul>$&\n</ul>');
        content = content.replace(/((?:<ul>\n?|\n)\s*)[*•](.*)/g, '$1<li>$2</li>');

        $measureContent.html(content);

        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `${url}?config=${config}`;
        document.getElementsByTagName('head')[0].appendChild(script);
    }
}
