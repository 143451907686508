import $ from 'jquery';

export default class CatalogSearch {
    constructor(selector = '.js-catalog-search') {
        this.$search = $(selector);
        if (!this.$search.length) {
            return;
        }

        this.selectors = {
            result: '.js-catalog',
            options: {
                tgn: '.js-catalog-search-filter-tgn',
                active: '.js-catalog-search-filter-active',
                sorting: '.js-catalog-search-sorting',
                search: '.js-catalog-search-input',
            },
        };
        this._addEventHandlers();
        this._buildUrl();
    }

    _addEventHandlers() {
        this.$search.find('form').on('submit', this._search.bind(this));
        this.$search.find('select').on('change', this._search.bind(this));
    }

    _buildUrl() {
        let url = new URL(window.location.href);

        for (const filter in this.selectors.options) {
            url.searchParams.delete(filter);
            let value = this.$search.find(this.selectors.options[filter]).val();
            if (value !== null && value !== '') {
                url.searchParams.set(
                    filter,
                    this.$search.find(this.selectors.options[filter]).val()
                );
            }
            this.$search
                .find(this.selectors.options[filter])
                .closest('.select')
                .toggleClass('active', value !== null && value !== '');
        }

        return url;
    }

    _search(e) {
        e.preventDefault();
        const url = this._buildUrl();

        location.replace(url);
    }
}
